import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`${css`
	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	body,
	html,
	#root {
		margin: 0;
		padding: 0;
		font-family: ${({ theme }) => theme.font};
		height: 100%;
		scroll-behavior: smooth;
		font-family: 'Cairo', sans-serif;
	}
`}`;
