import { css } from 'styled-components';

export const Container = css`
	width: 100%;
	padding: 0 0.75rem;
	margin: auto;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: 540px;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		max-width: 720px;
		padding: 0 2rem;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		max-width: 960px;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
		max-width: 1140px;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
		max-width: 1320px;
	}
`;

export const RmLastChildMargin = css`
	> * {
		:last-child {
			margin-bottom: 0;
		}
	}
`;

export const BgGradient = css`
	background-color: ${({ theme }) => theme.primary};
	background: ${({ theme }) => `linear-gradient(165deg, ${theme.primary} 15%, ${theme.secondary} 80%)`};
	color: #fff;
`;

export const Shadow1 = css`
	-webkit-box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.18), 0px 0px 8px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.18), 0px 0px 8px 0 rgba(0, 0, 0, 0.15);
`;

export const Shadow2 = css`
	-webkit-box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.2), 0px 0px 15px 0 rgba(0, 0, 0, 0.17);
	box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.2), 0px 0px 15px 0 rgba(0, 0, 0, 0.17);
`;

export const Shadow3 = css`
	-webkit-box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.3);
`;

export const SpinerKeyframes = css`
	@keyframes spinner {
		to {
			transform: rotate(360deg);
		}
	}

	@keyframes pulse {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes grow-pulse {
		0% {
			transform: scale(0.1);
			opacity: 0;
		}
		30% {
			opacity: 0.5;
		}
		60% {
			transform: scale(1.2);
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}
`;
