import React from 'react';
import styled from 'styled-components';
import { SpinerKeyframes } from 'theme/Mixins';

const sizeOptions = {
	sm: '0.35',
	lg: '1.5',
};

const StyledSpinner = styled.span`
	${SpinerKeyframes}
	margin: auto;

	width: ${({ size }) => 2.5 * (sizeOptions[size] ?? 1)}rem;
	height: ${({ size }) => 2.5 * (sizeOptions[size] ?? 1)}rem;

	border-radius: 50%;
	border-top: 0.2rem solid ${({ theme }) => theme.primary};
	border-right: 0.2rem solid rgba(255, 255, 255, 0);
	animation: spinner 0.6s linear infinite;
`;

const StyledFlexWrapper = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

const Loader = ({ size }) => <StyledSpinner size={size} />;

const LoaderCentered = ({ size }) => (
	<StyledFlexWrapper>
		<StyledSpinner size={size} />
	</StyledFlexWrapper>
);

Loader.Centered = LoaderCentered;
export default Loader;
