import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import GlobalStyles from 'theme/globalStyles';
import { ThemeProvider } from 'styled-components';
import AppTheme from 'theme/themeDefault';

import Loader from 'components/Loader';
import reportWebVitals from 'reportWebVitals';

const App = React.lazy(() => import('App'));

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={AppTheme}>
			<GlobalStyles />

			<Suspense fallback={<Loader.Centered size="lg" />}>
				<App />
			</Suspense>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
