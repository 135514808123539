const ThemeDefault = () => {
	const theme = {
		// hash color opacity: 75%: BF, 60%: 99, 50%: 80, 25%: 40
		primary: '#11226c',

		secondary: '#008dd2',
		secondaryDarken: '#0085c7',

		danger: '#ff000a',

		success: '#409613',

		grey100: 'hsl(0, 0%, 96%)',
		grey200: 'hsl(0, 0%, 90%)',
		grey300: 'hsl(0, 0%, 80%)',
		grey400: 'hsl(0, 0%, 70%)',
		grey500: 'hsl(0, 0%, 60%)',
		grey600: 'hsl(0, 0%, 50%)',
		grey700: 'hsl(0, 0%, 40%)',
		grey800: 'hsl(0, 0%, 30%)',
		grey900: 'hsl(0, 0%, 20%)',
		black: 'hsl(0, 0%, 0%)',

		opacity500: 'hsla(0, 0%, 0%, 0.5)',

		borderRadius25: '0.25rem',
		borderRadius50: '0.5rem',
		borderRadius75: '0.75rem',
		borderRadius100: '1rem',

		margin100: '6.25rem', // 100px
		margin50: '3.125rem', // 50 px
		margin30: '1.875rem', // 30 px
		margin20: '1.25rem', // 20 px

		weight: {
			light: 400,
			bold: 700,
		},

		breakpoints: {
			xs: '0',
			sm: '576px',
			md: '768px',
			lg: '992px',
			xl: '1200px',
			xxl: '1400px',
		},

		paddingY: '5rem 0',
	};

	return theme;
};

export default ThemeDefault;
